var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Recherche","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterStudents,"items-per-page":"25"},scopedSlots:_vm._u([{key:"item.nom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",on:{"click":function($event){return _vm.goToStud(item)}}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary",on:{"click":function($event){return _vm.$router.push({name: 'student-single', params :{id: item.id}})}}},[_vm._v(" "+_vm._s(item.prenom)+" "+_vm._s(item.nom))])])]}},{key:"tuteurs[0].nom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(" "+_vm._s(item.tuteurs.length > 0 ? item.tuteurs[0].nom : ''))])])]}},{key:"item.formation.nom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block text--primary"},[_vm._v(" "+_vm._s(item.formation.nom.substring(0, 15)))])])]}},{key:"item.tutorat_time",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block text--primary text-truncate"},[_vm._v(" "+_vm._s(_vm._f("date")(new Date(item.tutorat_time),'dd MMMM HH:mm')))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DateTimePickerVue',{attrs:{"button-text":_vm.icons.mdiPencil,"color":"primary","icon":true},on:{"newDate":function($event){return _vm.editTutoratTime($event, item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }